
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";

import stockConfig from "../../../config/stock-config.json";
import allowedFields from "../../../config/available-fields.json";

import DatePicker from "@/components/form-components/DatePicker.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Payments from "@/modules/orders/components/Payments.vue";
import CommonActions from "@/modules/orders/mixins/common_actions";
import Client from "@/modules/orders/mixins/client";
import Allocations from "@/modules/orders/mixins/allocations";
import Comments from "@/modules/orders/mixins/comments";
import Permissions from "@/modules/orders/mixins/permissions";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
import ServicesMixin from "@/modules/orders/mixins/services";
import OrderActions from "@/modules/orders/components/OrderActions.vue";
import orderActionsMixin from "@/modules/orders/mixins/orderActionsMixin";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "EvalCreateOrder",

  components: {
    FileUploader,
    OrderActions,
    OrderServices,
    Payments,
    DatePicker,
    ClientData
  },

  mixins: [
    CommonActions,
    ServicesMixin,
    Client,
    Allocations,
    orderActionsMixin,
    Comments,
    Permissions
  ],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  directives: { mask },

  data: () => ({
    rules: rules as any,
    stockConfig: stockConfig as any,
    allowedFields: allowedFields as any,
    selectedField: "" as string,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      client_representatives: [] as Array<any>,
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "client",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string,
        use_last_power_of_attorney: true as boolean
      } as any,
      details: {
        goods: [] as Array<any>
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      reasons: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0,
    totalSum: 0 as number,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ] as Array<any>,
    defaultCountry: null as any,
    comments: {} as any,
    oldModel: null as any,
    forceKey: 0 as number,
    contents: {} as any,
    powerOfAttorney: null as any,
    maxDocumentCounter: 20
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 0;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (this.model.hasOwnProperty("document_contents")) {
          for (const item of this.model.document_contents) {
            this.contents[item.type] = item;
          }
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    canEditOrder() {
      return this.model.order.status.alias === "processing";
    },
    needCertificationConfirmation() {
      return this.model.order.status.alias === "check_beneficiary";
    },
    expertsPercent() {
      return this.model.experts.reduce(
        (
          total: number,
          { percent, user_id }: { percent: number; user_id: number }
        ) => {
          if (Number(percent) && this.model.order.handler_id !== user_id) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    },
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    headers() {
      return this.stockConfig.headers.map((item: any) => ({
        ...item,
        text: this.$t(item.text)
      }));
    },
    disabledStatus() {
      return this.value?.order.status?.alias !== "processing";
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    addRow(): void {
      this.model.documents.push({ file_type: "conclusion" });
    },
    deleteRow(index: number): void {
      this.model.documents.splice(index, 1);
    },
    async reload(): Promise<void> {
      await this.$forceUpdate();
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const expertiseTypes = await API.expertiseTypes().getList();
        const experts = await API.users().getList({
          filter: {
            service_type: this.model.order.service_type_id,
            subdivision: this.model.order.subdivision_id
          }
        });

        this.selects.expertiseTypes = expertiseTypes;
        this.selects.experts = experts;

        const response = await Promise.all([
          API.purposeOfUse().getList(this.model.order.service_type_id, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.model.order.service_type_id),
          API.orders().getLanguages()
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.client_id = model.order.client.id;
          model.document_contents = [];

          for (const key in this.contents) {
            model.document_contents.push(this.contents[key]);
          }

          await this.$API.orders().edit(Number(this.$route.params.id), model);
          form.updateGuard();
          this.$emit("update");
          // await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        } else {
          // await setTimeout(() => {
          //   const firstErrorInput = form.inputs.find(
          //     (item: any) => item.messagesToDisplay.length
          //   );
          //
          //   if (firstErrorInput) {
          //     this.$vuetify.goTo(firstErrorInput.$el, {
          //       offset: 150
          //     });
          //   }
          // }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 0;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;

      this.setDefaultTab();
      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    setContent(event: string, key: string): void {
      if (!this.contents[key]) {
        this.contents[key] = {};
      }

      this.contents[key].content = event;
      this.contents[key].type = key;
    },
    setDocuments(event: any): void {
      this.model.documents = event;
    },
    setStock(event: Array<any>): void {
      this.model.details.goods = event;
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 0;
        return;
      } else {
        this.selectedTab = 2;
      }
    },
    async downloadDocument(): Promise<void> {
      // window.open(
      //   `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/pdf/last_certificate_templates`
      // );
      try {
        const file = await this.$API
          .orders()
          .printAaijOrder(Number(this.$route.params.id));
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async confirmCertificationData(): Promise<void> {
      try {
        await this.$API
          .orders()
          .changeOrderStatus(this.model.order.id, "checked_beneficiary");
        await this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
